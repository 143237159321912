var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('AddNewProject',{attrs:{"showSideText":true}})],1),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{attrs:{"md":"7"}},[_c('CTableWrapper',{attrs:{"items":_vm.getShuffledUsersData(),"small":"","caption":"Tâches"}})],1),_c('CCol',{attrs:{"md":"5"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" General ")]),_c('CCardBody',[_c('CChartPieExample')],1)],1)],1),_c('CCol',{attrs:{"md":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Projet "),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"#","rel":"noreferrer noopener","target":"_blank"}},[_c('small',{staticClass:"text-muted"},[_vm._v("See All")])])])]),_c('CCardBody',{staticClass:"table-responsive"},[_c('CDataTable',{staticClass:"m-0 table-borderless",attrs:{"hover":"","responsive":false,"items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":"","header":false},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CLink',{staticClass:"text-decoration-none",attrs:{"to":{
                  path: 'projets/' + item.user.name,
                }}},[_c('div',[_vm._v(_vm._s(item.user.name))]),_c('div',{staticClass:"small text-muted"},[_c('span',[(item.user.new)?[_vm._v("New")]:[_vm._v("Recurring")]],2),_vm._v(" | Crée le: "+_vm._s(item.user.registered)+" ")])])],1)}},{key:"country",fn:function(ref){
                var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('CIcon',{attrs:{"name":item.country.flag,"height":"25"}})],1)}},{key:"usage",fn:function(ref){
                var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"},[_c('strong',[_vm._v(_vm._s(item.usage.value)+"%")])]),_c('div',{staticClass:"float-right"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(item.usage.period))])])]),_c('CProgress',{staticClass:"progress-xs",attrs:{"color":_vm.color(item.usage.value)},model:{value:(item.usage.value),callback:function ($$v) {_vm.$set(item.usage, "value", $$v)},expression:"item.usage.value"}})],1)}}])},[_c('td',{attrs:{"slot":"activity"},slot:"activity"},[_c('CRow',{staticClass:"ml-4 d-flex justify-content-arround"},[_c('CButton',{staticClass:"mx-2",attrs:{"color":"primary","variant":"ghost","shape":"pill","size":"sm"}},[_c('CIcon',{staticClass:"mr-1 text-info",attrs:{"name":"cilList"}})],1),_c('CButton',{staticClass:"mx-2",attrs:{"color":"primary","variant":"ghost","shape":"pill","size":"sm"}},[_c('CIcon',{staticClass:"mr-1 text-info",attrs:{"name":"cilFolder"}})],1),_c('CButton',{staticClass:"mx-2",attrs:{"color":"primary","variant":"ghost","shape":"pill","size":"sm"}},[_c('CIcon',{staticClass:"mr-1 text-info",attrs:{"name":"cilPlus"}})],1)],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }